import * as React from "react";
import { DefaultLayout } from "../layouts/DefaultLayout";

const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <h1>Page not found</h1>
    </main>
  );
};

NotFoundPage.Layout = DefaultLayout;
export default NotFoundPage;
