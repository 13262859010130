import { Link } from "gatsby";
import React from "react";

export const DefaultLayout: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <Link to="/">Home</Link>
      <Link to="/game">Game</Link>
      {children}
    </>
  );
};
